import { computed, ref } from "vue";
import type {
  Workflow,
  WorkflowConclusion,
  WorkflowRecover,
  WorkflowResult,
  WorkflowResultParams,
  WorkflowStep,
} from "@/services/WorkflowService";
import type { RecoverConfiguration } from "@/services/RecoverConfigurationsService";

import { v4 as uuidv4 } from "uuid";
import { deepCopy } from "@/services/HelperService";
import {
  fetchComposerEnabledGatewayConnections,
  type GatewayConnection,
} from "@/services/GatewayService";

const steps = ref<WorkflowStep[]>([]);
const conclusions = ref<WorkflowConclusion[]>([]);
const loadingGateways = ref(false);
const gateways = ref<GatewayConnection[]>([]);
const workflow = ref<Workflow | undefined>(undefined);
const action = ref<"update" | "create">();
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const submitted = ref(false);
const recoverConfigurations = ref<RecoverConfiguration[]>([]);
const failureReasons = ref<string[]>([]);
const isSandbox = ref<boolean>(false);
export const setNewWorkflow = (currentEnvironmentKey: string) => {
  isSandbox.value = false;
  workflow.value = {
    name: "",
    sandbox: isSandbox.value,
    environment_key: currentEnvironmentKey,
    steps: [
      {
        uuid: uuidv4(),
        priority: 0,
        description: undefined,
        conclusions: [
          {
            uuid: uuidv4(),
            result: {
              uuid: uuidv4(),
              gateway_key: "",
              gateway_type: "",
              parent_company_id: "",
              params: {
                attempt_network_token: false,
                pan_fallback: false,
              },
            },
            weight: 100,
          },
        ],
      },
    ],
  };
};

export async function setGateways(
  orgKey: string,
  envKey: string,
  sandbox: boolean
) {
  loadingGateways.value = true;
  try {
    gateways.value = (
      await fetchComposerEnabledGatewayConnections(orgKey, envKey)
    ).filter((c) => {
      if (sandbox) {
        return c.sandbox || c.gateway_type === "test";
      }
      return !c.sandbox && c.gateway_type !== "test";
    }) as GatewayConnection[];
  } finally {
    loadingGateways.value = false;
  }
}

export const updateRecoverResult = (
  result: WorkflowResult,
  conclusionIndex: number,
  stepIndex: number,
  recoverPriority: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
      ?.results[recoverPriority]
  ) {
    steps.value[stepIndex].conclusions![
      conclusionIndex
    ].result.params.recover!.results[recoverPriority] = result;
  }
};

export const updateResult = (
  result: WorkflowResult,
  conclusionIndex: number,
  stepIndex: number
) => {
  if (steps.value[stepIndex].conclusions![conclusionIndex].result) {
    // if recover mode was added and then top level result was added do not override recover mode
    if (
      steps.value[stepIndex].conclusions![conclusionIndex].result.params
        ?.recover
    ) {
      // if no params object on result then create it
      if (!result.params) {
        result.params = {} as WorkflowResultParams;
      }

      result.params.recover =
        steps.value[stepIndex].conclusions![
          conclusionIndex
        ].result.params?.recover;

      // Set each recover result attempt_network_token value equal to that of top level result
      // update recover uuid to reload component
      steps.value[stepIndex].conclusions![
        conclusionIndex
      ].result.params?.recover?.results.forEach((r) => {
        r.params.attempt_network_token = result.params.attempt_network_token;
        r.params.pan_fallback = false;
        r.uuid = uuidv4();
      });
    }
    steps.value[stepIndex].conclusions![conclusionIndex].result = result;
  }
};

export const updateRecover = (
  newRecover: WorkflowRecover,
  conclusionIndex: number,
  stepIndex: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
  ) {
    steps.value[stepIndex].conclusions![conclusionIndex].result.params.recover =
      newRecover;
  }
};

export const addGatewaySplit = (stepIndex: number) => {
  steps.value[stepIndex].conclusions!.push({
    result: {
      uuid: uuidv4(),
      gateway_key: "",
      gateway_type: "",
      parent_company_id: "",
      params: {
        attempt_network_token: false,
        pan_fallback: false,
      },
    },
    weight: 0,
    uuid: uuidv4(),
  });
};

export const addRecovery = (conclusionIndex: number, stepIndex: number) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex] &&
    !steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
  ) {
    steps.value[stepIndex].conclusions![conclusionIndex].result.params.recover =
      {
        config_id: "",
        base_mode: "",
        results: [
          {
            uuid: uuidv4(),
            gateway_key: "",
            gateway_type: "",
            parent_company_id: "",
            params: {
              attempt_network_token:
                steps.value[stepIndex].conclusions![conclusionIndex].result
                  .params.attempt_network_token,
              pan_fallback: false,
            },
            priority: 0,
          },
        ],
      };
    steps.value[stepIndex].conclusions![conclusionIndex].uuid = uuidv4();
    steps.value[stepIndex].conclusions![conclusionIndex].result.uuid = uuidv4();
  }
};

export const addRecoverResult = (
  conclusionIndex: number,
  stepIndex: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
      ?.results?.length === 1
  ) {
    steps.value[stepIndex].conclusions![
      conclusionIndex
    ].result.params.recover?.results.push({
      uuid: uuidv4(),
      gateway_key: "",
      gateway_type: "",
      parent_company_id: "",
      params: {
        attempt_network_token:
          steps.value[stepIndex].conclusions![conclusionIndex].result.params
            .attempt_network_token,
        pan_fallback: false,
      },
      priority: 1,
    });
  }
};

export const removeSplit = (conclusionIndex: number, stepIndex: number) => {
  if (
    steps.value[stepIndex].conclusions!.length > 1 &&
    steps.value[stepIndex].conclusions![conclusionIndex]
  ) {
    steps.value[stepIndex].conclusions!.splice(conclusionIndex, 1);
    steps.value[stepIndex].conclusions![0].weight = 100;
  }
};

export const removeRecoverResult = (
  conclusionIndex: number,
  stepIndex: number,
  priority: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
      ?.results?.length === 2
  ) {
    steps.value[stepIndex].conclusions![
      conclusionIndex
    ].result.params.recover!.results.splice(priority, 1);
    steps.value[stepIndex].conclusions![
      conclusionIndex
    ].result.params.recover!.results[0].priority = 0;
  }
};

export const removeRecoveryFromConclusion = (
  conclusionIndex: number,
  stepIndex: number
) => {
  if (
    steps.value[stepIndex].conclusions![conclusionIndex].result.params?.recover
  ) {
    delete steps.value[stepIndex].conclusions![conclusionIndex].result.params
      .recover;
    // reload result component
    steps.value[stepIndex].conclusions![conclusionIndex].result.uuid = uuidv4();
  }
};

export const setSteps = (stepsOverride?: WorkflowStep[]) => {
  const _steps = stepsOverride
    ? deepCopy(stepsOverride)
    : deepCopy(workflow.value?.steps) || [];
  _steps.map((s: WorkflowStep) => (s.uuid = uuidv4()));
  _steps.forEach((s: WorkflowStep, index: number) => {
    if (!s.steps && s.conclusions) {
      // every step has a conclusion unless it has a step
      s.conclusions = setConclusions(s.conclusions);
    }
    s.priority = s.priority || index; //TODO possibly need to rethink this
  });
  _steps.sort((a: WorkflowStep, b: WorkflowStep) => a.priority - b.priority);
  steps.value = _steps;
};
const setConclusions = (conclusions: WorkflowConclusion[]) => {
  conclusions.map((c: WorkflowConclusion) => {
    c.uuid = uuidv4();
    if (c.result) {
      c.result.uuid = uuidv4();
      if (!c.result.params) {
        c.result.params = {
          attempt_network_token: false,
          pan_fallback: false,
        };
      }
      if (c.result.params.recover?.results) {
        c.result.params.recover.results.map((r: WorkflowResult, index) => {
          r.uuid = uuidv4();
          r.priority = r.priority || index;
        });
      }
    }
  });
  return conclusions;
};

export const updateWeight = (
  conclusion: WorkflowConclusion,
  conclusionIndex: number,
  stepIndex: number
) => {
  if (steps.value[stepIndex].conclusions?.length === 2) {
    if (conclusionIndex === 0) {
      steps.value[stepIndex].conclusions![1].weight = 100 - conclusion.weight;
    } else {
      steps.value[stepIndex].conclusions![0].weight = 100 - conclusion.weight;
    }
  }
};

export const resetComposable = () => {
  gateways.value = [];
  workflow.value = undefined;
  steps.value = [];
  action.value = undefined;
  formState.value = "saveChanges";
  submitted.value = false;
  recoverConfigurations.value = [];
  failureReasons.value = [];
  isSandbox.value = false;
};

const selectedGatewayTypes = computed(() => {
  const arr = Array.from(
    new Set(JSON.stringify(steps.value).match(/"gateway_type":"[a-z_]*"/g))
  ).map((item) => {
    return item.replace('"gateway_type":', "").replaceAll('"', "");
  });
  // create workflow will add an "" gateway, this has to be removed for payment capabilities drawer to open
  if (arr.indexOf("") !== -1) {
    arr.splice(arr.indexOf(""), 1);
  }
  return arr;
});

export function useWorkflow() {
  return {
    conclusions,
    steps,
    loadingGateways,
    gateways,
    workflow,
    action,
    formState,
    submitted,
    selectedGatewayTypes,
    failureReasons,
    recoverConfigurations,
    isSandbox,
    setNewWorkflow,
    resetComposable,
    updateResult,
    updateRecoverResult,
    updateRecover,
    addRecoverResult,
    addRecovery,
    removeSplit,
    removeRecoverResult,
    removeRecoveryFromConclusion,
    updateWeight,
    addGatewaySplit,
    setSteps,
  };
}
