<template>
  <div>
    <SpreedlyPageTitle :title="$t('recover.createConfiguration')" breadcrumbs>
      <template v-slot:breadcrumbs>
        <router-link
          :to="{
            name: 'RecoverConfigurationList',
          }"
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
        >
          <i class="pi pi-arrow-left relative mr-1 pb-4 text-[0.75rem]"></i>
          {{ $t("recover.recoverConfiguration", 2) }}
        </router-link>
      </template>
    </SpreedlyPageTitle>
    <SpreedlyContainer>
      <form
        class="h-full w-full flex flex-col px-6"
        @keyup.enter="save(v.$invalid)"
        @submit.prevent="save(v.$invalid)"
      >
        <Message
          v-if="displayErrorMessage"
          class="justify-start -mt-8 -mx-14"
          severity="error"
          :closable="false"
          >{{ displayErrorMessage }}
        </Message>
        <div class="mt-8 mb-2">
          <label
            for="recover-configuration-description-input"
            class="font-bold"
            :class="{
              '!text-spreedly-red': v.description.$invalid && submitted,
            }"
            >{{ $t("description") }}</label
          >
          <InputText
            id="recover-configuration-description-input"
            v-model="v.description.$model"
            :disabled="!hasPermission"
            :maxlength="100"
            :pt="{
              input: { class: 'pl-0 pr-2 font-sans' },
              root: {
                class:
                  v.description.$invalid && submitted
                    ? '!border-spreedly-red'
                    : '',
              },
            }"
          />
          <div v-if="v.description.$invalid && submitted">
            <small class="p-error">{{ v.description.required.$message }}</small>
          </div>
        </div>

        <div class="my-6">
          <label
            for="retryable-failure-reasons-multiselect"
            class="font-bold"
            :class="{
              '!text-spreedly-red':
                v.retryable_failure_reasons.$invalid && submitted,
            }"
            >{{ $t("recover.failureReasons") }}</label
          >
          <i18n-t
            scope="global"
            keypath="recover.failureReasonDocumentation"
            tag="p"
            class="whitespace-pre-line text-sm my-1"
          >
            <template v-slot:link>
              <a
                class="text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
                href="#"
                target="_blank"
                >{{ $t("seeDocumentation") }}</a
              >
            </template>
          </i18n-t>
          <MultiSelect
            v-model="selectedFailureReasons"
            :showClear="!v.retryable_failure_reasons.$model"
            :disabled="!hasPermission"
            input-id="retryable-failure-reasons-multiselect"
            filter
            display="chip"
            @change="updateState()"
            :options="retryableFailureReasonOptions"
            optionLabel="name"
            class="w-full"
            :pt="{
              root: {
                class:
                  v.retryable_failure_reasons.$invalid && submitted
                    ? 'border border-spreedly-red !text-spreedly-red'
                    : 'border border-spreedly-gray-300',
              },
            }"
          />
          <div v-if="v.retryable_failure_reasons.$invalid && submitted">
            <small class="p-error">{{
              v.retryable_failure_reasons.required.$message
            }}</small>
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div
            v-if="hasPermission"
            class="flex flex-shrink-0 flex-wrap items-center justify-start mt-4"
          >
            <SpreedlyButton
              class="mr-4"
              :disabled="!v.$anyDirty || formState === 'saving' || isLoading"
              :text="$t('cancel')"
              id="cancel-create-recover-configuration-button"
              :inverse="true"
              @click="cancel()"
            ></SpreedlyButton>
            <SpreedlyButton
              class="!mr-0"
              :disabled="!v.$anyDirty || formState === 'saving' || isLoading"
              @click="save(v.$invalid)"
              id="create-recover-configuration-button"
              :icon="{ position: 'left', state: formState }"
              :text="$t('save')"
            ></SpreedlyButton>
          </div>
        </div>
      </form>
    </SpreedlyContainer>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, reactive, ref } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import Message from "primevue/message";

import { listFailureReasons } from "@/services/RecoverConfigurationsService";
import { createRecoverConfiguration } from "@/services/RecoverConfigurationsService";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { sentenceCase } from "@/services/HelperService";
import i18n from "@/i18n";

import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyContainer from "@/components/SpreedlyContainer.vue";
import router from "@/router";
import { onBeforeRouteLeave } from "vue-router";
import { listWorkflowsByRecoverConfiguration } from "@/services/WorkflowService";

const store = useSettingsStore();
const formState = ref<"saveChanges" | "saving" | "saved">("saveChanges");
const submitted = ref(false);
const displayErrorMessage = ref();
const selectedFailureReasons = ref<{ name: string; code: string }[]>([]);
const failureReasons = ref<string[]>([]);
const isLoading = ref(false);

const state = reactive({
  description: "",
  retryable_failure_reasons: [] as string[],
});

const rules = {
  description: { required },
  retryable_failure_reasons: { required },
};

const v = useVuelidate(rules, state, { $scope: "create-recover-config" });

const retryableFailureReasonOptions = computed(() => {
  let optionsArray: { name: string; code: string }[] = [];
  failureReasons.value.forEach((reason) => {
    optionsArray.push({ name: sentenceCase(reason), code: reason });
  });
  return optionsArray;
});

const hasPermission = computed(() => {
  return store.hasPermission("organization.create_workflow");
});

onMounted(async () => {
  isLoading.value = true;
  try {
    failureReasons.value = await listFailureReasons(
      store.currentOrganization.key,
      store.currentEnvironment.key!
    );
  } catch (err) {
    displayErrorMessage.value = i18n.global.t("recover.httpError", {
      support: "support@spreedly.com",
    });
  } finally {
    isLoading.value = false;
  }
});

function cancel() {
  state.description = "";
  state.retryable_failure_reasons = [];
  selectedFailureReasons.value = [];
  reset();
}
function reset() {
  v.value.$reset();
  submitted.value = false;
  isLoading.value = false;
}

onBeforeRouteLeave(() => {
  if (v.value.$anyDirty) {
    const answer = window.confirm(i18n.global.t("unsavedChanges"));
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

async function save(isFormInvalid: boolean) {
  if (!v.value.$anyDirty || formState.value === "saving") {
    return;
  }

  displayErrorMessage.value = null;

  if (!hasPermission.value) {
    displayErrorMessage.value = i18n.global.t("permission_denied_edit");
    return;
  }

  submitted.value = true;
  if (isFormInvalid) {
    return;
  }
  isLoading.value = true;
  try {
    formState.value = "saving";
    const config = await createRecoverConfiguration(
      store.currentOrganization.key,
      store.currentEnvironment.key!,
      {
        recover_configuration: {
          description: state.description,
          environment_key: store.currentEnvironment.key!,
          retryable_failure_reasons: state.retryable_failure_reasons,
        },
      }
    );
    reset();
    await router.push({
      name: "RecoverConfigurationSettings",
      params: { id: config.key },
      state: { successMessage: "true" },
    });
  } catch (err) {
    displayErrorMessage.value = i18n.global.t("errorMessage.generic");
    formState.value = "saveChanges";
    reset();
  }
}

function updateState() {
  v.value.$touch();
  state.retryable_failure_reasons = [];
  selectedFailureReasons.value.map((reason) => {
    state.retryable_failure_reasons.push(reason.code);
  });
}
</script>
