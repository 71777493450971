<template>
  <div v-if="steps[stepIndex].conclusions">
    <div
      v-if="store.hasComposerEnabledGatewayConnections()"
      class="flex flex-row mt-2 mb-4 p-4 bg-[#EEEEEE] rounded-md w-fit"
    >
      <div class="flex flex-col w-full">
        <div class="flex flex-row mx-1 mb-2">
          <span
            class="text-spreedly-gray-600 mr-4"
            v-if="steps[stepIndex].conclusions!.length > 1"
            >{{ `Split ${conclusionIndex + 1}` }}</span
          >
          <button
            v-if="steps[stepIndex]?.conclusions!.length > 1"
            :disabled="!hasPermission"
            :id="`remove-split-${stepIndex}-${conclusionIndex}`"
            type="button"
            @click="$emit('removeSplit', conclusionIndex)"
            class="remove-split-button text-spreedly-blue-700 hover:text-spreedly-blue-800 underline disabled:text-spreedly-gray-400"
          >
            {{ $t("removeSplit") }}
          </button>
        </div>

        <div
          class="bg-white rounded-lg border-2 p-2 flex flex-row min-w-[678px] w-fit"
        >
          <div class="self-center mx-2">
            {{
              steps[stepIndex].conclusions!.length > 1
                ? $t("send")
                : $t("sendTo")
            }}
          </div>
          <div
            class="self-center mx-2"
            v-if="steps[stepIndex].conclusions!.length > 1"
          >
            <div class="flex flex-row">
              <InputNumber
                v-model="v.weight.$model"
                :disabled="!hasPermission"
                buttonLayout="vertical"
                :aria-label="`workflow-split-${stepIndex}-${conclusionIndex}`"
                :key="steps[stepIndex].conclusions![conclusionIndex].uuid"
                :inputId="`workflow-split-${stepIndex}-${conclusionIndex}`"
                @update:model-value="updateWorkflow()"
                mode="decimal"
                :pt="{
                  incrementButton: { root: { class: 'ml-1' } },
                  decrementButton: { root: { class: 'ml-1' } },
                  input: {
                    root: {
                      class: 'rounded-r-none !pr-0 !shadow-none',
                    },
                  },
                  root: {
                    class: '!h-[75px] !w-[45px]',
                  },
                }"
                showButtons
                :min="0"
                :max="100"
              />
              <div
                class="self-center bg-[#e1e1e1] rounded-r-md pr-2 h-[27px] pt-[2px] -ml-[4px]"
                :class="{ '!opacity-[0.6]': !hasPermission }"
              >
                %
              </div>
            </div>
          </div>
          <div
            class="self-center mx-2"
            v-if="steps[stepIndex].conclusions!.length > 1"
          >
            {{ $t("to") }}
          </div>
          <div class="self-center mx-2 w-fit">
            <WorkflowResult
              :key="conclusion.result.uuid"
              :result="conclusion.result"
              :step-index="stepIndex"
              :conclusion-index="conclusionIndex"
            ></WorkflowResult>
          </div>
        </div>
        <div class="self-center relative py-2">
          <mdicon class="text-spreedly-blue-700" name="arrowDown"></mdicon>
        </div>
        <WorkflowRecover
          :key="conclusion.result.uuid"
          @remove-recover="removeRecovery"
          v-if="conclusion.result.params?.recover"
          :recover="
            steps[stepIndex].conclusions![conclusionIndex].result.params?.recover!
          "
          :step-index="stepIndex"
          :conclusion-index="conclusionIndex"
        ></WorkflowRecover>
        <div
          class="bg-white rounded-lg border-2 p-2 min-w-[678px] w-fit"
          v-if="
            !conclusion.result.params?.recover ||
            !store.currentOrganization.allow_recover
          "
        >
          <SpreedlyButton
            v-if="!conclusion.result.params?.recover"
            :disabled="
              !hasPermission ||
              (!store.currentOrganization.allow_recover && !isSandbox)
            "
            :text="$t('recover.add')"
            :id="`add-recovery-strategy-button-${stepIndex}-${conclusionIndex}`"
            :icon="{ name: 'plus', position: 'left' }"
            inverse
            class="font-normal w-full"
            @click="
              addRecovery(props.conclusionIndex, props.stepIndex);
              v.$touch();
            "
          ></SpreedlyButton>
          <div v-if="!store.currentOrganization.allow_recover">
            <div class="flex flex-row mt-4">
              <mdicon
                name="information"
                class="text-spreedly-blue-700 pr-2"
                data-testid="info-icon"
              ></mdicon>
              <div class="font-semibold">
                {{ $t("recover.premiumService") }}
              </div>
            </div>

            <i18n-t
              scope="global"
              keypath="recover.docs"
              tag="p"
              class="whitespace-pre-line text-sm ml-8"
            >
              <template v-slot:learnMore>
                <a
                  class="underline text-spreedly-blue-600 hover:text-spreedly-blue-700 cursor-pointer"
                  :href="`${docsUrl}/docs/recover-user-guide`"
                  target="_blank"
                  >{{ $t("learnMore").toLowerCase() }}</a
                >
              </template>
            </i18n-t>
            <i18n-t
              scope="global"
              :keypath="'recover.reachOut'"
              tag="p"
              class="text-caption whitespace-pre-line ml-8 mt-2"
            >
              <template v-slot:support>
                <a
                  class="underline cursor-pointer"
                  href="mailto:support@spreedly.com"
                  >{{ $t("spreedlySupport") }}</a
                >
              </template>
            </i18n-t>
          </div>
        </div>
      </div>
    </div>
    <Message
      v-else
      severity="info"
      :closable="false"
      :pt="{ root: { class: 'bg-spreedly-blue-200' } }"
    >
      <i18n-t
        :keypath="'workflows.noGateways'"
        tag="p"
        class="text-lg"
        scope="global"
      >
        <template v-slot:supportedGateway>
          <router-link
            :id="`marketplace-link-${stepIndex}-${conclusionIndex}`"
            class="marketplace-link text-spreedly-blue-600 underline hover:text-spreedly-blue-700 cursor-pointer"
            :to="{ name: 'Marketplace' }"
            >{{ $t("workflows.supportedGateway") }}
          </router-link>
        </template>
      </i18n-t>
    </Message>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Message from "primevue/message";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {
  addRecovery,
  removeRecoveryFromConclusion,
  updateWeight,
  useWorkflow,
} from "@/composables/useWorkflow";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import WorkflowRecover from "@/components/WorkflowRecover.vue";
import WorkflowResult from "@/components/WorkflowResult.vue";
import type { WorkflowConclusion } from "@/services/WorkflowService";
import useEventBus from "@/composables/useEventBus";
import { deepCopy } from "@/services/HelperService";

const { action, steps, isSandbox } = useWorkflow();
const store = useSettingsStore();

const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});
const docsUrl = import.meta.env.VITE_DOCS_URL;

const props = defineProps<{
  conclusion: WorkflowConclusion;
  conclusionIndex: number;
  stepIndex: number;
}>();

defineEmits(["removeSplit"]);

const state = reactive(props.conclusion);
const rules = {
  weight: { required },
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });
const { emit } = useEventBus();

const removeRecovery = () => {
  emit("removeRecover", deepCopy(steps.value));
  removeRecoveryFromConclusion(props.conclusionIndex, props.stepIndex);
  v.value.$touch();
};

watch(isSandbox, () => {
  if (!isSandbox.value && !store.currentOrganization.allow_recover) {
    removeRecoveryFromConclusion(props.conclusionIndex, props.stepIndex);
  }
});

function updateWorkflow() {
  if (!v.value.$anyDirty) {
    return;
  }

  v.value.$touch();
  updateWeight(state, props.conclusionIndex, props.stepIndex);
}
</script>
<style lang="css" scoped>
:deep(.p-inputtext.p-inputnumber-input) {
  background-color: #e1e1e1;
  border: 0;
  margin-left: -0.25rem;
  padding-left: 1rem;
  border-radius: 0.375rem 0 0 0.375rem !important;
}
:deep(.p-inputtext:enabled:focus) {
  outline: 1px solid transparent;
  box-shadow: 0 0 0 0;
  outline-offset: 2px;
}
</style>
