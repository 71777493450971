<template>
  <div>
    <SpreedlyPageTitle
      :title="$t('gateway_.allGatewayConnections')"
      :breadcrumbs="store.hasAccessToMarketplace"
    >
      <template v-slot:info>
        <div
          class="flex flex-col lg:flex-row lg:justify-between pb-8 mt-2 md:-mt-6 text-spreedly-gray-600 text-caption"
        >
          <p>{{ $t("infoMessage.gateways") }}</p>
        </div>
      </template>

      <template v-slot:breadcrumbs>
        <Button
          class="text-spreedly-blue-600 hover:text-spreedly-blue-700"
          icon="pi pi-arrow-left"
          type="button"
          id="back-to-marketplace"
          icon-pos="left"
          text
          :label="$t('marketplace')"
          @click="router.push({ name: 'Marketplace' })"
        ></Button>
      </template>
    </SpreedlyPageTitle>
    <div
      v-if="store.hasPermission('environment.create_gateway')"
      class="mb-8 -mt-4"
    >
      <div class="relative flex flex-row justify-between xl:w-1/3 w-full">
        <SpreedlyButton
          :text="$t('gateway_.createConnection')"
          id="create-new-gateway-button"
          :icon="{ name: 'plus', position: 'left' }"
          @click="router.push({ name: 'GatewayCreate' })"
        ></SpreedlyButton>
      </div>
    </div>
    <div
      class="mb-2 overflow-y-auto"
      v-if="
        currentEnvironment?.gateways &&
        currentEnvironment.gateways.length > 0 &&
        !isLoading
      "
    >
      <div
        v-for="(gateway, index) in paginatedGateways"
        :key="index"
        class="w-full inline-flex overflow-hidden drop-shadow-sm my-2 rounded-lg bg-white"
      >
        <div
          class="grid grid-cols-1 lg:grid-cols-2 w-[calc(100%-4rem)] gap-x-4 gap-y-1 pl-4 lg:pl-12 pr-4 py-4 rounded-l-lg text-spreedly-gray-600 border-l border-t border-b"
        >
          <div class="col-span-1 min-w-0 flex flex-row">
            <div class="text-ellipsis whitespace-nowrap overflow-hidden">
              <span
                id="gateway-name"
                class="font-bold text-lg cursor-pointer"
                @click="
                  router.push({
                    name: 'GatewayDetailSummary',
                    params: {
                      id: gateway.key,
                    },
                  })
                "
                >{{ gateway.name }}</span
              >
            </div>
            <div v-if="gateway.state === 'redacted'">
              <Tag
                class="mx-4"
                :value="$t('redacted')"
                severity="info"
                rounded
              ></Tag>
            </div>
            <div v-else-if="gateway.sandbox">
              <Tag
                :pt="{
                  root: {
                    class:
                      'mx-4 border border-success-green bg-success-green-light text-spreedly-gray-600',
                  },
                }"
                class="mx-4 whitespace-nowrap"
                :value="$t('sandbox')"
                severity="sandbox"
                rounded
              ></Tag>
            </div>
          </div>

          <div
            v-if="width < 768"
            class="col-span-1 text-sm text-ellipsis whitespace-nowrap overflow-hidden"
          >
            <span class="font-normal">{{ gateway.description }}</span>
          </div>
          <div
            class="col-span-1 text-sm whitespace-nowrap overflow-hidden text-ellipsis relative flex flex-row pt-2 -mt-2"
          >
            <span class="font-bold -mt-0.5">{{ $t("gateway_token") }}</span>
            <SpreedlyCopyToClipboard
              inline
              id="gateway-token"
              :token="gateway.key"
            ></SpreedlyCopyToClipboard>
          </div>
          <div
            v-if="width >= 768"
            class="col-span-1 text-sm text-ellipsis whitespace-nowrap overflow-hidden"
          >
            <span class="font-normal">{{ gateway.description }}</span>
          </div>
          <div
            class="col-span-1 text-sm text-ellipsis whitespace-nowrap overflow-hidden -mt-2 lg:mt-0"
          >
            <span class="font-bold">{{ $t("creationDate") }}</span>
            <span class="font-normal ml-2">
              {{ formatDate(gateway.created_at) }}</span
            >
          </div>
        </div>
        <div class="border-r border-t border-b rounded-r-lg p-4 text-lg">
          <div class="flex flex-row justify-between lg:pt-2">
            <div class="mx-2">
              <Button
                :title="$t('gateway_.details')"
                icon="pi pi-cog pi-text-override"
                :id="`gateway-settings-button-${gateway.key}`"
                class="p-button-text p-button-rounded !text-spreedly-gray-600 !shadow-none !hover:text-spreedly-gray-700 hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
                @click="
                  router.push({
                    name: 'GatewayDetailSummary',
                    params: {
                      id: gateway.key,
                    },
                  })
                "
              >
              </Button>
            </div>
            <div class="mx-2">
              <Button
                type="button"
                :id="`gateway-transactions-${gateway.key}`"
                :title="$t('gateway_.recentTransactions')"
                icon="pi pi-list pi-text-override"
                class="p-button-text p-button-rounded !text-spreedly-gray-600 !shadow-none !hover:text-spreedly-gray-700 hover:enabled:bg-spreedly-blue-300 !hover:bg-spreedly-blue-300 focus:bg-spreedly-blue-300"
                @click="
                  router.push({
                    name: 'GatewayTransactions',
                    params: { id: gateway.key },
                  })
                "
              >
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mb-8 text-spreedly-gray-600">
      <SpreedlyLoadingSpinner v-if="isLoading" />
      <span v-else class="text-xl">{{ $t("gateway_.noGateways") }}</span>
    </div>
    <div
      v-if="
        currentEnvironment.gateways &&
        currentEnvironment.gateways.length > 5 &&
        !isLoading
      "
    >
      <Paginator
        :totalRecords="totalRecords"
        :rows="numRows"
        :rowsPerPageOptions="[5, 10, 15, 20]"
        template="PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        v-model:first="currentPage"
        @update:first="(newPage) => (currentPage = newPage)"
        @update:rows="(newRows) => (numRows = newRows)"
        :pt="{
          root: ' mb-8 border py-0 drop-shadow-sm rounded-lg bg-white',
          current: { class: 'text-spreedly-gray-600' },
          paginator: {
            root: {
              class: 'font-sans flex flex-row',
            },
            rowPerPageDropdown: {
              root: {
                class:
                  'flex !ml-4 !mb-0 border border-spreedly-gray-400 ml-4 items-center !w-[5.2rem]',
              },
            },
          },
        }"
      ></Paginator>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { storeToRefs } from "pinia";
import SpreedlyPageTitle from "@/components/SpreedlyPageTitle.vue";
import SpreedlyButton from "@/components/SpreedlyButton.vue";
import SpreedlyCopyToClipboard from "@/components/SpreedlyCopyToClipboard.vue";
import router from "@/router";
import { formatDate } from "@/services/HelperService";
import Button from "primevue/button";
import Tag from "primevue/tag";
import Paginator from "primevue/paginator";
import { useBreakpoints } from "@/composables/useBreakpoints";
import SpreedlyLoadingSpinner from "@/components/SpreedlyLoadingSpinner.vue";

const store = useSettingsStore();
const { currentEnvironment } = storeToRefs(store);
const { width } = useBreakpoints();
const isLoading = ref();

onMounted(() => {
  getRecentGateways();
});

watch(
  () => currentEnvironment.value.key,
  () => {
    getRecentGateways();
  }
);

const totalRecords = ref(currentEnvironment.value.gateways?.length);

async function getRecentGateways() {
  isLoading.value = true;
  try {
    await store.fillGateways(true);
    totalRecords.value = currentEnvironment.value.gateways?.length;
  } finally {
    isLoading.value = false;
  }
}

const currentPage = ref(0);
const numRows = ref(10);

const paginatedGateways = computed(() => {
  const start = currentPage.value > 0 ? currentPage.value : 0;
  const end = start + numRows.value;
  return currentEnvironment.value.gateways?.slice(start, end);
});
</script>
